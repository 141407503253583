import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import { Row, Col } from 'react-bootstrap';
import Loader from '../../Loader/Loader';

require('./SubscriptionCard.scss');


const mapDate = (date) => {
  const dateObject = new Date(date);
  return `${(`0${dateObject.getDate()}`).substr(-2)}-${(`0${dateObject.getMonth() + 1}`).substr(-2)}-${dateObject.getFullYear()}`; // eslint-disable-line max-len
};

class SubscriptionCard extends Component {
  static propTypes = {
    subscription: PropTypes.objectOf(PropTypes.any).isRequired,
    type: PropTypes.string,
  }

  static defaultProps = {
    type: 'current',
  }

  constructor() {
    super();
    this.state = { loading: false };
  }

  render() {
    const { subscription, type } = this.props;
    const { loading } = this.state;
    return (
      <div className={
        ClassNames('subscriptioncard', { 'subscriptioncard--historic': type === 'historic' })}
      >
        <div className={
          ClassNames('subscriptioncard__content', { 'subscriptioncard__content--historic': type === 'historic' })}
        >
          <Row className="subscriptioncard__row">
            <Col xs={12} sm={8} className="subscriptioncard__col">
              <span className="subscriptioncard__date">
                {mapDate(subscription.startdate)}
                {' '}
                -
                {' '}
                {mapDate(subscription.enddate)}
                {type === 'upcoming' && <span className="subscriptioncard__label--upcoming"> binnenkort actief</span>}
              </span>
              <h1 className="subscriptioncard__title">
                {subscription.name}
              </h1>
            </Col>
          </Row>

          <Row className="subscriptioncard__row last">
            <Col xs={12} sm={8} className="subscriptioncard__col">
              <p>
            Bedrag:
                {' '}
                {new Intl.NumberFormat('nl-NL', {
                  style: 'currency',
                  currency: 'EUR',
                  minimumFractionDigits: 2,
                }).format(subscription.amount)}
                <br />
              Betaald op:
                {' '}
                {mapDate(subscription.paymentdate)}
              </p>
            </Col>
          </Row>

          {loading && (
          <div className="subscriptioncard__loading">
            <Loader size={48} color="dark-grey" />
          </div>
          )}
        </div>
      </div>
    );
  }
}

export default SubscriptionCard;
