import React from 'react';
import PropTypes from 'prop-types';
import './NewsItem.scss';


const NewsItem = ({ item }) => (
  <div className="newsitem">
    <div className="newsitem__header">
      <span className="newsitem__title">{item.title}</span>
      <span className="newsitem__date">{item.post_date}</span>
    </div>
    <div className="newsitem__content" dangerouslySetInnerHTML={{ __html: item.contents }} />
  </div>
);

NewsItem.propTypes = {
  item: PropTypes.objectOf(PropTypes.any).isRequired,
};


export default NewsItem;
