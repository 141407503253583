import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Loader from '../Loader/Loader';


class StaticPage extends Component {
    static propTypes = {
      page: PropTypes.string.isRequired,
    }

    constructor() {
      super();
      this.state = { pageData: {}, loading: true };
    }

    componentDidMount() {
      this.fetchPage();
    }

    fetchPage = async () => {
      const { page } = this.props;
      const data = await fetch(`${process.env.CONF_API_URL}/api/content/page/${page}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      })
        .then(res => res.json());
      if (!data.success) {
        this.setState({ loading: false, errorMsg: data.error });
      } else {
        this.setState({ loading: false, pageData: data.data, errorMsg: '' });
      }
    }


    render() {
      const { loading, pageData, errorMsg } = this.state;
      return (
        <section className="profile">
          {loading && <Loader size={36} color="light-grey" /> }
          {loading && errorMsg && <p>{errorMsg}</p> }
          {!loading && pageData && (
          <div>
            <h1>{pageData.title}</h1>
            <div dangerouslySetInnerHTML={{ __html: pageData.contents }} />
          </div>
          )}

        </section>
      );
    }
}

export default StaticPage;
