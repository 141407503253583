import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import Loader from '../Loader/Loader';
import './Profile.scss';


class Profile extends Component {
  constructor() {
    super();
    this.state = { profile: {}, loading: true, errors: null, updated: false }; // eslint-disable-line
  }

  componentDidMount() {
    this.fetchProfile();
  }

  FieldError = (field) => {
    const { errors } = this.state;
    return (errors && errors[field] ? <span className="profile__form__fieldError">{errors[field]}</span> : '');
  }

  handleChange = (e) => {
    const { profile } = this.state;
    profile[e.target.name] = e.target.value === 'false' ? false : e.target.value;
    this.setState({ profile });
  }

  handleSubmit = async (e) => {
    const { profile } = this.state;
    e.preventDefault();
    this.setState({ loading: true, errors: null });
    const data = await fetch(`${process.env.CONF_API_URL}/api/users/me/profile`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
      body: JSON.stringify(profile),

    }).then((res) => {
      if (res.status === 401) {
        window.location = '/auth/login?redirect=/profile';
      } return res.json();
    });

    if (!data.success) {
      this.setState({ loading: false, errors: data.errors });
    } else {
      this.setState({ updated: true });
      this.fetchProfile();
    }
  }

  fetchProfile = async () => {
    const data = await fetch(`${process.env.CONF_API_URL}/api/users/me/profile`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    }).then((res) => {
      if (res.status === 401) {
        window.location = '/auth/login?redirect=/profile';
      } return res.json();
    });

    if (!data.success) {
      this.setState({ loading: false, errors: data.errors });
    } else {
      this.setState({ loading: false, profile: data.data, errors: null });
    }
  }


  render() {
    const {
      loading, profile, errorMsg, errors, updated,
    } = this.state;
    return (
      <section className="profile">
        <h1>Profiel</h1>
        {loading && <Loader size={36} color="light-grey" /> }
        {loading && errorMsg && <p>{errorMsg}</p> }

        {errors && (
        <span className="notification notification--warning mb-1 mt-1">
          <strong>Let op: </strong>
          Één of meerdere velden bevatten fouten, je aanpassingen zijn niet opgeslagen!
        </span>
        )}

        {!errors && !loading && updated && (
        <span className="notification notification--success mb-1 mt-1">
           Je wijzigingen zijn succesvol opgeslagen!
        </span>
        )}


        {!loading && profile
        && (
        <Row className="profile__form">

          <form onSubmit={this.handleSubmit}>
            <Row>
              <Col xs={12} md={6}>
                <Row>
                  <Col xs={12} sm={8}>
                    <label>Voornaam:</label>
                    <input
                      type="text"
                      name="firstName"
                      readOnly
                      value={profile.firstName}
                      onChange={this.handleChange}
                    />
                  </Col>
                  <Col xs={12} sm={4}>
                    <label>Initialen:</label>
                    <input type="text" name="initials" value={profile.initials} onChange={this.handleChange} />
                    {this.FieldError('initials')}
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} sm={4}>
                    <label>Tussenvoegsels:</label>
                    <input
                      type="text"
                      name="prefix"
                      readOnly
                      value={profile.prefix || ''}
                      onChange={this.handleChange}
                    />
                  </Col>
                  <Col xs={12} sm={8}>
                    <label>Achternaam:</label>
                    <input type="text" name="lastName" readOnly value={profile.lastName} onChange={this.handleChange} />
                  </Col>
                </Row>

                <Row>
                  <Col xs={12} sm={8}>
                    <label>Straat:</label>
                    <input type="text" name="street" value={profile.street} onChange={this.handleChange} />
                    {this.FieldError('street')}
                  </Col>
                  <Col xs={12} sm={4}>
                    <label>Huisnummer:</label>
                    <input type="text" name="housenumber" value={profile.housenumber} onChange={this.handleChange} />
                    {this.FieldError('housenumber')}
                  </Col>
                </Row>

                <Row>
                  <Col xs={12} sm={4}>
                    <label>Postcode:</label>
                    <input type="text" name="postcode" value={profile.postcode} onChange={this.handleChange} />
                    {this.FieldError('postcode')}
                  </Col>
                  <Col xs={12} sm={8}>
                    <label>Plaats:</label>
                    <input type="text" name="city" value={profile.city} onChange={this.handleChange} />
                    {this.FieldError('city')}
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    &nbsp;
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    <label>GSN Nummer:</label>
                    <input type="text" name="gsn" value={profile.gsn} onChange={this.handleChange} />
                    <small>Staat op je digitale NGF pas begint met NL gevolgd door 8 cijfers</small>
                    {this.FieldError('gsn')}
                  </Col>
                </Row>
              </Col>
              <Col xs={12} md={6}>
                <Row>
                  <Col xs={12}>
                    <label>E-Mailadres:</label>
                    <input type="email" name="email" readOnly value={profile.email} onChange={this.handleChange} />
                    {this.FieldError('email')}
                  </Col>
                </Row>
                <Row>
                  <Col sm={12}>
                    <label>Telefoonnummer:</label>
                    <input type="phone" name="telephone" value={profile.telephone} onChange={this.handleChange} />
                    {this.FieldError('telephone')}
                  </Col>
                  {/* <Col sm={4}>
                    <label>NGF-nummer:</label>
                    <input type="text" name="ngf_number" value={profile.ngf_number} onChange={this.handleChange} />
                    {this.FieldError('ngf_number')}
                  </Col> */}
                </Row>
                <Row>
                  <Col sm={4}>
                    <label>Geslacht:</label>
                    <select name="gender" onChange={this.handleChange} value={profile.gender}>
                      <option value="M">Man</option>
                      <option value="F">Vrouw</option>
                    </select>
                    {this.FieldError('gender')}
                  </Col>
                  <Col sm={8}>
                    <label>Geboortedatum:</label>
                    <input type="date" name="birthdate" value={profile.birthdate} onChange={this.handleChange} />
                    {this.FieldError('birthdate')}
                  </Col>
                </Row>
                <Row>
                  <Col sm={4}>
                    <label>Lid van een golfclub?:</label>
                    <input
                      type="radio"
                      name="is_club_member"
                      checked={profile.is_club_member}
                      id="member_1"
                      value="true"
                      onChange={this.handleChange}
                    />
                    <label htmlFor="member_1">Ja</label>
                    <input
                      type="radio"
                      name="is_club_member"
                      checked={!profile.is_club_member}
                      id="member_0"
                      value="false"
                      onChange={this.handleChange}
                    />
                    <label htmlFor="member_0">Nee</label>
                  </Col>
                  <Col sm={4}>
                    <label>Handicap</label>
                    <input
                      type="number"
                      name="handicap"
                      value={profile.handicap}
                      onChange={this.handleChange}
                      autoComplete="off"
                      step="0.01"
                    />
                  </Col>
                </Row>
                {profile.is_club_member && (
                <Row>
                  <Col sm={12}>
                    <label>Naam van club:</label>
                    <input
                      type="text"
                      readOnly={!profile.is_club_member}
                      name="golfassociation"
                      value={profile.golfassociation}
                      onChange={this.handleChange}
                      autoComplete="off"
                    />
                  </Col>
                </Row>)}
              </Col>
            </Row>

            <Row>
              <Col className="profile__actions">
                <button type="submit">Wijzig gegevens</button>
              </Col>
            </Row>
          </form>
        </Row>
        )
        }
      </section>
    );
  }
}

export default Profile;
