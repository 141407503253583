import { Component } from 'react';

class LogoutPage extends Component {
  componentDidMount() {
    this.handleLogout();
  }

  handleLogout = () => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('email');
    window.location = '/auth/login';
  }

  render() {
    return '&nbsp;';
  }
}


export default LogoutPage;
