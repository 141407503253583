import React, { Component } from 'react';
import PropTypes from 'prop-types';
// import classNames from 'classnames';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Grid, Row, Col } from 'react-bootstrap';

import './LoginPage.scss';
import '../ReservationWizardAutoComplete/ReservationWizardAutoComplete.scss';
import queryString from 'query-string';
import Loader from '../Loader/Loader';
import errorIcon from '../ReservationWizardAutoComplete/error-icon.svg';


class Impersonate extends Component {
  static propTypes = {
    location: PropTypes.objectOf(PropTypes.any).isRequired, //eslint-disable-line
  }

  constructor() {
    super();
    this.state = {
      loginError: false, loading: false,
    };
  }

  componentDidMount() {
    this.requestToken();
  }

  onSubmit = (e) => {
    e.preventDefault();
    this.requestToken();
    this.setState({ loading: true });
  }

  requestToken = () => {
    const { location } = this.props;
    const values = queryString.parse(location.search);
    const { id, login } = values;

    const payload = {
      id_token: id,
      login_token: login,
    };

    fetch(`${process.env.CONF_API_URL}/api/token/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    })
      .then(response => this.handleTokenRequest(response));
  }

  handleTokenRequest = (response) => {
    this.setState({ loading: false, loginError: '' });

    if (response.status === 200) {
      this.handleUserLogin(response);
    } else if (response.status === 401) {
      this.setState({ loginError: 'foutieve login' });
    } else {
      this.setState({ loginError: 'onbekende fout' });
    }
  }

  handleUserLogin = async (response) => {
    const data = await response.json();
    localStorage.setItem('access_token', data.access_token);
    window.location = '/reservations';
  }

  render() {
    const { loginError, loading } = this.state;
    return (
      <section className="wizardStep wizardStepBack">

        <Grid className=" wizardStep__section">
          <div className="wizardStep__section__wrapper">
            <Row>
              <Col xs={12} sm={6} smOffset={3}>
                <div className="wizard-autocomplete">
                  <div className="wizard-autocomplete-modifier">
                    {loading && <Loader size={24} color="light-grey" /> }
                    {!loading && loginError
                        && <>
                          <img src={errorIcon} alt="" />
                          <span className="wizard-autocomplete-error">{loginError}</span>
                        </>}
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Grid>
      </section>
    );
  }
}


const mapStateToProps = ({ reservation }) => ({ reservation });

export default connect(mapStateToProps)(withRouter(Impersonate));
