import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './FaqItem.scss';


const FaqItem = ({ item, searchQuery }) => {
  const [open, setOpen] = useState(false);
  const [answer, setAnswer] = useState();

  const toggleItem = (e) => {
    e.preventDefault();
    setOpen(!open);
  };

  const searchText = async () => {
    await setAnswer(item.answer);
    const regex = new RegExp(searchQuery, 'mgi');
    const index = item.answer && searchQuery ? item.answer.toLowerCase().indexOf(searchQuery.toLowerCase()) : -1;

    if (index === -1) {
      setOpen(false);
      return false;
    }
    setOpen(true);
    setAnswer(item.answer.replace(regex,
      `<span class="faq-search-filter">${searchQuery}</span>`));
    return {};
  };

  useEffect(() => {
    searchText();
  }, [searchQuery]);

  return (
    <div className={classNames('faqitem', { 'faqitem--open': open })}>
      <a href="#1" className="faqitem__question" onClick={toggleItem}>{item.question}</a>
      <div className="faqitem__answer">
        <p dangerouslySetInnerHTML={{ __html: answer }} />
      </div>
    </div>
  );
};

FaqItem.propTypes = {
  item: PropTypes.objectOf(PropTypes.any).isRequired,
  searchQuery: PropTypes.string.isRequired,
};


export default FaqItem;
