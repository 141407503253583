import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import { Row, Col } from 'react-bootstrap';
import Loader from '../../Loader/Loader';

require('./RenewSubscriptionCard.scss');


class RenewSubscriptionCard extends Component {
  static propTypes = {
    offer: PropTypes.objectOf(PropTypes.any).isRequired,
  }

  constructor() {
    super();
    this.state = { loading: false };
  }

  render() {
    const { offer } = this.props;
    const { loading } = this.state;
    return (
      <div className={
        ClassNames('renewsubscriptioncard')}
      >
        <div className={
          ClassNames('renewsubscriptioncard__content')}
        >
          <Row className="renewsubscriptioncard__row">
            <Col xs={12} className="renewsubscriptioncard__col">
              <span className="renewsubscriptioncard__amount">
                {new Intl.NumberFormat('nl-NL', {
                  style: 'currency',
                  currency: 'EUR',
                  minimumFractionDigits: 2,
                }).format(offer.amount)}
              </span>
              <h1 className="renewsubscriptioncard__title">
                {offer.name}
              </h1>
            </Col>
          </Row>

          <Row className="renewsubscriptioncard__row last">
            <Col xs={12} className="renewsubscriptioncard__col">
              <p>
                <ul className="renewsubscriptioncard__properties">
                  {offer.properties.map(property => (<li>{property}</li>))}
                </ul>
                <a href={offer.url} target="_blank" rel="noopener noreferrer">&raquo; kies dit abonnement</a>
              </p>
            </Col>
          </Row>

          {loading && (
          <div className="renewsubscriptioncard__loading">
            <Loader size={48} color="dark-grey" />
          </div>
          )}
        </div>
      </div>
    );
  }
}

export default RenewSubscriptionCard;
