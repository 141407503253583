import { combineReducers } from 'redux';
import ReservationReducer from './ReservationReducer';
import ServerDataReducer from './ServerDataReducer';
import UserReducer from './UserReducer';

export default combineReducers({
  reservation: ReservationReducer,
  serverData: ServerDataReducer,
  user: UserReducer,
});
