import React, { Component } from 'react';
import Loader from '../../Loader/Loader';
import NewsItem from '../NewsItem/NewsItem';


class NewsOverview extends Component {
  constructor() {
    super();
    this.state = { newsItems: [], loading: true };
  }

  componentDidMount() {
    this.fetchItems();
  }

  fetchItems = async () => {
    const data = await fetch(`${process.env.CONF_API_URL}/api/news`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    })
      .then(res => res.json());
    if (!data.success) {
      this.setState({ loading: false, errorMsg: data.error });
    } else {
      this.setState({ loading: false, newsItems: data.data, errorMsg: '' });
    }
  }


  render() {
    const { loading, newsItems, errorMsg } = this.state;
    return (
      <section className="profile">
        <h1>Nieuws</h1>
        {loading && <Loader size={36} color="light-grey" /> }
        {loading && errorMsg && <p>{errorMsg}</p> }
        {!loading && newsItems
        && newsItems.map(item => (<NewsItem key={item.title} item={item} />))
        }
      </section>
    );
  }
}

export default NewsOverview;
