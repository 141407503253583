import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { SetDate, SetFirstSelector } from '../../../redux/actions/ReservationActions';

import ReservationWizardButtons from '../../ReservationWizardButtons/ReservationWizardButtons';
import Loader from '../../Loader/Loader';
import ReservationCloseButton from '../../ReservationWizard/ReservationCloseButton/ReservationCloseButton';

require('./ReservationDateStep.scss');

class ReservationDateStep extends Component {
  reservableDays = 14;

  // eslint-disable-next-line max-len
  months = ['', 'januari', 'februari', 'maart', 'april', 'mei', 'juni', 'juli', 'augustus', 'september', 'oktober', 'november', 'december'];

  constructor() {
    super();
    this.state = {
      loading: true,
      foundDates: {},
      dates: [],
      errorMsg: '',
    };
  }

  componentDidMount() {
    this.renderPickerDates();
    this.fetchDates();
  }

  fetchDates = async () => {
    const { reservation } = this.props;
    const body = {
      holes: reservation.holes,
      golfclub: reservation.golfclub ? reservation.golfclub.id : null,
      players: reservation.players.map(player => player.email),
    };
    const data = await fetch(`${process.env.CONF_API_URL}/api/reservations/availability`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
      body: JSON.stringify(body),
    })
      .then((res) => { if (res.status === 401) { window.location = '/auth/login'; } return res.json(); });
    if (!data.success) {
      this.setState({ loading: false, errorMsg: data.error || 'fout bij het ophalen van speeldata.' });
      return;
    }
    this.setState({ foundDates: data.data, loading: false, errorMsg: '' });
    this.renderFoundDates();
  }

  handleMouseEnter = (date) => {
    if (date && date.found && !date.reservable) {
      this.setState({ errorMsg: date.reason });
    }

    if (date && date.found && date.reservable && date.bonus_needed) {
      const names = date.bonus_players.join(', ');
      const message = `Op deze datum wordt een bonusspeelrecht van ${names} gebruikt`;
      this.setState({ errorMsg: message });
    }
  }

  handleClick = (date) => {
    const { setDate } = this.props;
    if (date && date.found && !date.reservable) {
      this.setState({ errorMsg: date.reason });
    } else if (date && date.found && date.reservable) {
      setDate(date.fullDate);
    }
  }

  handleMouseLeave = (date) => {
    if (date && date.found && (!date.reservable || date.bonus_needed)) {
      this.setState({ errorMsg: '' });
    }
  }

   handleSwitchSelector = (e) => {
     const { setFirstSelector, history } = this.props;
     e.preventDefault();
     setFirstSelector('course');
     history.push('/reservation/course');
   }

   renderFoundDates() {
     const { dates, foundDates } = this.state;
     if (foundDates) {
       const foundDateKeys = Object.keys(foundDates);
       const newDates = dates.map(date => ({
         ...date,
         ...foundDates[date.fullDate],
         found: !!foundDates[date.fullDate],
         isFirstOfBlock: foundDateKeys.indexOf(date.fullDate) === 0,
         isLastOfBlock: foundDateKeys.indexOf(date.fullDate) === foundDateKeys.length - 1,
       }));
       this.setState({ dates: newDates });
     }
   }

   renderPickerDates() {
     const today = new Date();
     const dayOfWeek = today.getDay() || 7;
     const lastMonday = dayOfWeek === 1 ? new Date() : new Date(new Date().setDate(today.getDate() - dayOfWeek + 1));
     const daysToShow = dayOfWeek ? this.reservableDays + 7 : this.reservableDays;
     const dateArray = [];
     let month;


     for (let i = 0; i < daysToShow; i++) { // eslint-disable-line no-plusplus
       const date = new Date(lastMonday.getTime());
       date.setDate(date.getDate() + i);
       const dateObj = {
         day: date.getDate(),
         month: date.getMonth() + 1,
         // eslint-disable-next-line max-len
         fullDate: `${date.getFullYear()}-${(`0${date.getMonth() + 1}`).substr(-2)}-${(`0${date.getDate()}`).substr(-2)}`,
         isToday: date.getDate() === today.getDate(),
       };
       dateArray.push(dateObj);

       if (dateArray[0].month === dateArray[dateArray.length - 1].month) {
         month = this.months[dateArray[0].month];
       } else {
         month = `${this.months[dateArray[0].month]} / ${this.months[dateArray[dateArray.length - 1].month]}`;
       }
     }

     this.setState({ dates: dateArray, month });
   }

   render() {
    const { loading, dates, month, errorMsg } = this.state; // eslint-disable-line
     const { reservation } = this.props;
     return (
       <section className="wizardStep wizardStepBack">
         <Grid className=" wizardStep__section">
           <ReservationCloseButton />
           <div className="wizardStep__section__wrapper reservationDate-wrapper">
             <h2>Datum</h2>
             <h1>Kies een datum</h1>
             <span className="reservationDate-wrapper__switchtype">
              &nbsp;
               {reservation.firstSelector === 'date' && (
               <span>
of
                 {' '}
                 <a href="/#" onClick={this.handleSwitchSelector}>kies eerst een baan &raquo;</a>
               </span>
               )}
             </span>

             {loading && <Loader className="dataLoader" size={36} />}
             {!loading && (
             <div className="calendar">
               <span className="calendar__month">{month}</span>
               <div className="calendar__days">
                 <span>ma</span>
                 <span>di</span>
                 <span>wo</span>
                 <span>do</span>
                 <span>vr</span>
                 <span>za</span>
                 <span>zo</span>
               </div>

               <div className="calendar__dates">
                 {dates.map(date => (
                   <span
                     className={classNames({
                       today: date.isToday,
                       reservable: date.found && date.reservable,
                       notReservable: date.found && !date.reservable,
                       warning: date.found && date.reservable && date.bonus_needed,
                       firstOfBlock: date.isFirstOfBlock,
                       lastOfBlock: date.isLastOfBlock,
                       selected: reservation.date === date.fullDate,
                     })}
                     onMouseEnter={() => { this.handleMouseEnter(date); }}
                     onMouseLeave={() => { this.handleMouseLeave(date); }}
                     onClick={() => { this.handleClick(date); }}
                   >
                     {date.day}

                   </span>
                 ))}
               </div>

               <div className={classNames('calendar__error', { active: errorMsg })}>
                 {errorMsg}
               </div>
             </div>
             )}


             <ReservationWizardButtons
               backText={reservation && reservation.firstSelector === 'date'
                 ? 'terug naar holes' : 'terug naar banen'}
               backPath={reservation && reservation.firstSelector === 'date'
                 ? '/reservation/holes' : '/reservation/course'}
               nextPath={reservation && reservation.firstSelector === 'date'
                 ? '/reservation/course' : '/reservation/overview'}
               nextText={reservation && reservation.firstSelector === 'date' ? 'verder' : 'controleer'}
               nextDisabled={!reservation.date}
             />
           </div>
         </Grid>
       </section>
     );
   }
}

ReservationDateStep.propTypes = {
  reservation: PropTypes.objectOf(PropTypes.any).isRequired,
  setDate: PropTypes.func.isRequired,
  setFirstSelector: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

const mapStateToProps = ({ reservation }) => ({ reservation });
const mapDispatchToProps = { setDate: SetDate, setFirstSelector: SetFirstSelector };
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ReservationDateStep));
