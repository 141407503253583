import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';


import './ReservationCloseButton.scss';
import { ResetReservation } from '../../../redux/actions/ReservationActions';

const iconClose = require('./icon-close.svg');


class ReservationCloseButton extends Component {
    static propTypes = {
        resetReservation: PropTypes.func.isRequired, //eslint-disable-line
      history: PropTypes.objectOf(PropTypes.any).isRequired,
    }

    onClick = (e) => {
      e.preventDefault();
      const { resetReservation, history } = this.props;
      resetReservation();
      history.push('/');
    }

    render() {
      return (
        <a href="/" className="cancel-reservation" onClick={this.onClick}>
          <span>stop reservering</span>
          <img src={iconClose} alt="X" />
        </a>
      );
    }
}


const mapDispatchToProps = { resetReservation: ResetReservation };
const mapStateToProps = () => ({ });

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ReservationCloseButton));
