
export const FetchData = (scope, endpoint, headers = {}) => ({
  type: 'FETCH_DATA',
  payload: { scope, endpoint, headers },
});

export const FetchedData = response => ({
  type: 'FETCHED_DATA',
  payload: { response },
});
