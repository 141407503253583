import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { SetGolfclub, SetFirstSelector } from '../../../redux/actions/ReservationActions';

import ReservationWizardButtons from '../../ReservationWizardButtons/ReservationWizardButtons';
import Loader from '../../Loader/Loader';
import ReservationCloseButton from '../../ReservationWizard/ReservationCloseButton/ReservationCloseButton';
import ReservationGolfclubMap from './ReservationGolfclubMap';

require('./ReservationGolfclubStep.scss');

class ReservationGolfclubStep extends Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      golfclubs: [],
      filteredGolfclubs: [],
      filterActive: false,
      filterNeedle: '',
      selectedGolfclub: 0,
      displayState: 'clubs',
    };
  }

  componentDidMount() {
    this.fetchClubs();
  }

  fetchClubs = async () => {
    const { reservation } = this.props;
    const body = {
      holes: reservation.holes,
      players: reservation.players.map(player => player.email),
      date: reservation.date || null,
    };
    const data = await fetch(`${process.env.CONF_API_URL}/api/reservations/golfclubs`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
      body: JSON.stringify(body),
    })
      .then((res) => { if (res.status === 401) { window.location = '/auth/login'; } return res.json(); });

    this.setState({ loading: false, golfclubs: data.data });
    this.filterCourses();
  }

  handleInputBlur = () => {
    const { filterNeedle } = this.state;
    this.setState({ filterActive: filterNeedle.length });
  }

  handleInputFocus = () => {
    this.setState({ filterActive: true });
  }

  handleInputChange = (e) => {
    this.setState({ filterNeedle: e.target.value });
    this.filterCourses(e.target.value);
  }

  handleSwitchSelector = (e) => {
    const { setFirstSelector, history } = this.props;
    e.preventDefault();
    this.setselectedGolfclub(null);
    setFirstSelector('date');
    history.push('/reservation/date');
  }

  handleInputKeyDown = (e) => {
    const { selectedGolfclub, filteredGolfclubs } = this.state;
    const index = filteredGolfclubs.indexOf(
      filteredGolfclubs.filter(golfclub => golfclub.id === selectedGolfclub)[0],
    ) || 0;
    if (e.keyCode === 38 && index > 0) {
      e.preventDefault();
      this.setselectedGolfclub(filteredGolfclubs[index - 1].id);
    } else if (e.keyCode === 40 && index < filteredGolfclubs.length - 1) {
      e.preventDefault();
      this.setselectedGolfclub(filteredGolfclubs[index + 1].id);
    }
  }

  setselectedGolfclub = (golfclub, redirect = false) => {
    const { setGolfclub, history, reservation } = this.props;

    if (!golfclub) {
      this.setState({ selectedGolfclub: null });
      setGolfclub(null);
      return;
    }
    if (!golfclub.reservable) { return; }
    const { selectedGolfclub } = this.state;
    if (golfclub && golfclub.id && selectedGolfclub === golfclub.id) { return; }
    this.setState({ selectedGolfclub: golfclub.id });
    setGolfclub(golfclub);

    if (redirect) {
      history.push(reservation && reservation.firstSelector === 'date'
        ? '/reservation/overview' : '/reservation/date');
    }
  }


  switchView = (e, view) => {
    e.preventDefault();
    this.setState({ displayState: view });
  }

  filterCourses(needle = '') {
    const { golfclubs } = this.state;
    // const { reservation } = this.props;

    const lNeedle = needle.toLowerCase();
    const filteredGolfclubs = !needle.length
      ? golfclubs
      : golfclubs.filter(golfclub => golfclub.name.toLowerCase().indexOf(lNeedle) >= 0
    || golfclub.city.toLowerCase().indexOf(lNeedle) >= 0
    || (golfclub.keywords && golfclub.keywords.toLowerCase().indexOf(lNeedle) >= 0));
    this.setState({ filterNeedle: needle, filteredGolfclubs });
    // if (!reservation.isPredefinedCourse) {
    //   this.setselectedGolfclub(null);
    // }
  }

  render() {
    const { golfclubs, filteredGolfclubs, loading, filterActive, selectedGolfclub, displayState } = this.state; // eslint-disable-line
    const { reservation } = this.props;
    return (
      <section className="wizardStep wizardStepBack">
        <Grid className=" wizardStep__section">
          <ReservationCloseButton />

          <div className="wizardStep__section__wrapper reservationCourse-wrapper">
            <h2>Golfclub</h2>
            <h1>Kies een golfclub</h1>
            <span className="reservationCourse-wrapper__switchtype">
              &nbsp;
              {reservation.firstSelector !== 'date' && (
              <span>
of
                {' '}
                <a href="/#" onClick={this.handleSwitchSelector}>kies eerst een datum &raquo;</a>
              </span>
              )}
            </span>

            {loading && <Loader className="dataLoader" size={36} />}

            {displayState === 'clubs' && (
              <div>
                <a
                  href="/#"
                  className="reservationCourse-map__switch-view"
                  onClick={(e) => { this.switchView(e, 'map'); }}
                >
&raquo; bekijk kaartweergave

                </a>
                <div className="reservationCourse-clubfilter">


                  {/* <div className="reservationCourse-clubfilter__filterInfo">
                {`${filteredGolfclubs.length} / ${courses.length} banen worden getoond`}
              </div> */}
                  <input
                    type="text"
                    className={classNames('reservationCourse-clubfilter__input', { active: filterActive })}
                    placeholder="zoek of filter.."
                    onFocus={this.handleInputFocus}
                    onBlur={this.handleInputBlur}
                    onChange={this.handleInputChange}
                    onKeyDown={this.handleInputKeyDown}
                  />
                  <ul className="reservationCourse-clubfilter__list">
                    {filteredGolfclubs && filteredGolfclubs.map(golfclub => ( // eslint-disable-next-line
                  <li className={classNames({ 
                    selected: reservation.golfclub && reservation.golfclub.id
                    && golfclub.id === reservation.golfclub.id,
                    disabled: !golfclub.reservable,
                  })}
                    onClick={() => { this.setselectedGolfclub(golfclub); }}
                    key={golfclub.id}
                  >
                    <span className="reservationCourse-clubfilter__name">{golfclub.name}</span>
                    <span className="reservationCourse-clubfilter__city">{golfclub.city}</span>
                    {!golfclub.reservable
                    && <span className="disabled-message">{golfclub.reason}</span>}
                    <div className="reservationCourse-clubfilter__instruction">
                      <span>{golfclub.reservation_instruction}</span>
                    </div>
                  </li>
                    ))}
                  </ul>
                </div>
              </div>
            )}

            {displayState === 'map' && golfclubs && (
            <div className="reservationCourse-map">
              <a
                href="/#"
                className="reservationCourse-map__switch-view"
                onClick={(e) => { this.switchView(e, 'clubs'); }}
              >
&raquo; bekijk lijstweergave

              </a>

              <ReservationGolfclubMap golfclubs={golfclubs} setSelectedGolfclub={this.setselectedGolfclub} />
            </div>
            )}


            <ReservationWizardButtons
              backText={reservation && reservation.firstSelector === 'date' ? 'terug naar datum' : 'terug naar holes'}
              backPath={reservation && reservation.firstSelector === 'date'
                ? '/reservation/date' : '/reservation/holes'}
              nextPath={reservation && reservation.firstSelector === 'date'
                ? '/reservation/overview' : '/reservation/date'}
              nextText={reservation && reservation.firstSelector === 'date'
                ? 'controleer' : 'verder'}
              nextDisabled={!reservation.golfclub || reservation.golfclub === 0}
            />
          </div>
        </Grid>
      </section>
    );
  }
}

ReservationGolfclubStep.propTypes = {
  reservation: PropTypes.objectOf(PropTypes.any).isRequired,
  setGolfclub: PropTypes.func.isRequired,
  setFirstSelector: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

const mapStateToProps = ({ reservation }) => ({ reservation });
const mapDispatchToProps = { setGolfclub: SetGolfclub, setFirstSelector: SetFirstSelector };
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ReservationGolfclubStep));
