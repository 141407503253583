import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import ReservationWizardButtons from '../../ReservationWizardButtons/ReservationWizardButtons';
import ReservationWizardAutoComplete from '../../ReservationWizardAutoComplete/ReservationWizardAutoComplete';
import ReservationWizardPlayersOverview from '../../ReservationWizardPlayersOverview/ReservationWizardPlayersOverview';
import ReservationCloseButton from '../../ReservationWizard/ReservationCloseButton/ReservationCloseButton';


const ReservationPartnersStep = ({ reservation: { players } }) => (
  <section className="wizardStep wizardStepBack">

    <Grid className=" wizardStep__section">
      <ReservationCloseButton />
      <div className="wizardStep__section__wrapper">
        <h2>Medespelers</h2>
        <h1>Geef eventueel je medespelers op</h1>
        <Row className="margin-bottom">
          <Col xs={12} sm={6}>
                Voeg toe op e-mailadres
          </Col>
        </Row>

        <Row>
          <ReservationWizardAutoComplete />
        </Row>

        <Row>
          <Col xs={12}>
            <ReservationWizardPlayersOverview />
          </Col>
        </Row>

        <ReservationWizardButtons
          backHidden
          nextDisabled={!players.length}
          nextPath="/reservation/holes"
          nextText={players.length < 2 ? 'verder, ik speel alleen' : 'verder'}
        />
      </div>
    </Grid>
  </section>
);

ReservationPartnersStep.propTypes = {
  reservation: PropTypes.objectOf(PropTypes.any).isRequired,
};
const mapStateToProps = ({ reservation }) => ({ reservation });
export default connect(mapStateToProps)(ReservationPartnersStep);
