const defaultState = { players: [] };

export default (state = { ...defaultState }, action) => {
  switch (action.type) {
    case 'RESET_RESERVATION':
      return { ...defaultState };

    case 'SET_HOLES':
      return { ...state, holes: action.payload.holes };

    case 'SET_GOLFCLUB':
      return { ...state, golfclub: action.payload.golfclub };

    case 'SET_DATE':
      return { ...state, date: action.payload.date };

    case 'RESERVATION_ADD_PLAYER':
      return { ...state, players: [...state.players, action.payload.player] };

    case 'RESERVATION_REMOVE_PLAYER': {
      const index = state.players.indexOf(action.payload.player);
      const players = [...state.players];
      players.splice(index, 1);
      return { ...state, players };
    }

    case 'SET_FIRST_SELECTOR': {
      return { ...state, firstSelector: action.payload.selector };
    }

    case 'SET_PREDEFINED_COURSE': {
      return { ...state, isPredefinedCourse: action.payload.isPredefined };
    }

    default:
      return state;
  }
};
