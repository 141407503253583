import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { SetGolfclub, SetPredefinedCourse } from '../../../redux/actions/ReservationActions';

import './CourseInfoItem.scss';

class CourseInfoItem extends Component {
  setSelectedClub() {
    const {
      item, setGolfclub, setPredefinedCourse, history,
    } = this.props;
    const club = {
      id: item.id,
      city: item.city,
      name: item.name,
      short_name: item.short_name,
      reservation_instruction: item.reservation_instruction,
    };
    setPredefinedCourse(true);
    setGolfclub(club, true);
    history.push('/reservation/players');
  }

  render() {
    const { item } = this.props;
    return (
      <div className="courseinfo">
        <div className="courseinfo__header">
          <span className="courseinfo__city">{item.city}</span>
          <span className="courseinfo__title">{item.name}</span>
        </div>
        <div className="courseinfo__content">

          <table className="courseinfo__table">
            <tbody>
              <tr>
                <th style={{ width: '75%' }}>Starttijden</th>
                <th>Rondes gespeeld</th>
              </tr>
              <tr>
                <td>{item.starttime_description}</td>
                <td>{`${item.your_plays} / ${item.your_maximum_plays}`}</td>
              </tr>
              <br />
              <button
                onClick={() => { this.setSelectedClub(); }}
                className="btn btn-success"
                type="button"
              >
bekijk beschikbaarheid & reserveer
              </button>
            </tbody>
          </table>
        </div>
      </div>);
  }
}


CourseInfoItem.propTypes = {
  item: PropTypes.objectOf(PropTypes.any).isRequired,
  setGolfclub: PropTypes.func.isRequired, //eslint-disable-line
  setPredefinedCourse: PropTypes.func.isRequired, //eslint-disable-line
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};


const mapStateToProps = ({ reservation }) => ({ reservation });
const mapDispatchToProps = { setGolfclub: SetGolfclub, setPredefinedCourse: SetPredefinedCourse };
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CourseInfoItem));
