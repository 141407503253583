export const resetReservation = () => ({
  type: 'RESET_RESERVATION',
});

export const SetPartnerType = type => ({
  type: 'SET_PARTNER_TYPE',
  payload: { type },
});

export const SetHoles = holes => ({
  type: 'SET_HOLES',
  payload: { holes },
});

export const SetGolfclub = golfclub => ({
  type: 'SET_GOLFCLUB',
  payload: { golfclub },
});

export const SetDate = date => ({
  type: 'SET_DATE',
  payload: { date },
});

export const AddPlayer = player => ({
  type: 'RESERVATION_ADD_PLAYER',
  payload: { player },
});

export const RemovePlayer = player => ({
  type: 'RESERVATION_REMOVE_PLAYER',
  payload: { player },
});

export const MakeReservation = () => ({
  type: 'MAKE_RESERVATION',
});

export const ResetReservation = () => ({
  type: 'RESET_RESERVATION',
});

export const SetFirstSelector = selector => ({
  type: 'SET_FIRST_SELECTOR',
  payload: { selector },
});

export const SetPredefinedCourse = isPredefined => ({
  type: 'SET_PREDEFINED_COURSE',
  payload: { isPredefined: !!isPredefined },
});
