import React, { Component } from 'react';
import { Grid } from 'react-bootstrap';
import Loader from '../../Loader/Loader';
import ReservationCard from '../ReservationCard/ReservationCard';

class HistoricReservationOverview extends Component {
  constructor() {
    super();
    this.state = { loading: true, errorMsg: '', reservations: []}; // eslint-disable-line
  }

  componentDidMount() {
    this.fetchHistoricReservations();
  }

  fetchHistoricReservations = async () => {
    this.setState({ loading: true });

    const data = await fetch(`${process.env.CONF_API_URL}/api/reservations/history`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    })
      .then((res) => {
        if (res.status === 401) {
          window.location = '/auth/login?redirect=/reservations/history';
        } return res.json();
      });
    if (!data.success) {
      this.setState({ loading: false, errorMsg: data.error || 'fout bij het ophalen van reserveringen' });
    } else {
      this.setState({ loading: false, reservations: data.data });
    }
  }


  render() {
    const { loading, reservations, errorMsg } = this.state; // eslint-disable-line

    return (
      <section className="wizardStep wizardStep--withMenu">
        <Grid className="wizardStep__section">
          <h2 className="mb-2">Speelgeschiedenis</h2>

          <div className="wizardStep__section__wrapper">
            {loading && <Loader className="dataLoader" size={36} />}

            {!loading && !reservations.length && !errorMsg
            && (
            <p>
              Er zijn geen reserveringen gevonden!
            </p>
            )}
            {!loading && errorMsg && (
            <p>
              {`<strong>Fout bij het ophalen van reserveringen:</strong> ${errorMsg}`}
            </p>
            )}

            {!loading && reservations.map(reservation => (
              <ReservationCard
                reservation={reservation}
                key={reservation.reservation_id}
                onRefresh={this.fetchReservations}
                pastReservation
              />))}
          </div>

        </Grid>
      </section>
    );
  }
}

export default HistoricReservationOverview;
