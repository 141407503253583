import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';

import Loader from '../../Loader/Loader';
import './FaqList.scss';
import FaqItem from '../FaqItem/FaqItem';

// import CourseInfoItem from '../FaqItem/FaqItem';


const FaqList = () => {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState();

  const fetchItems = async () => {
    setLoading(true);
    const data = await fetch(`${process.env.CONF_API_URL}/api/faq`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    })
      .then(res => res.json());

    if (!data.success) {
      setLoading(false);
      setCategories([]);
    } else {
      setLoading(false);
      setCategories(data.data);
    }
  };

  useEffect(() => {
    fetchItems();
  }, []);

  const handleSearch = (e) => {
    if (e && e.target) {
      // debugger; //eslint-disable-line
      if (e.target.value.length > 2) {
        setSearchQuery(e.target.value);
      } else {
        setSearchQuery(null);
      }
    }
  };

  return (
    <section className="faq">
      <Row>

        <Col xs={8}>
          <h1>FAQ</h1>
          <input type="text" placeholder="zoeken door FAQ" className="input-search" onChange={handleSearch} />
        </Col>
        <Col xs={4} className="pt-2 text-right">
          <br />
          <a href="https://www.nlgs.nl/files/Algemene_Voorwaarden_NLGS.pdf">Algemene voorwaarden</a>
        </Col>
      </Row>

      {loading && <Loader size={36} color="light-grey" /> }
      {!loading && categories
        && categories.map(category => (
          <div>
            <span className="faq__category-title">
              {category.name}
            </span>
            {category.questions.map(question => (
              <FaqItem
                item={question}
                searchQuery={searchQuery}
                key={question.question}
              />
            ))}
          </div>
        ))
        }
    </section>
  );
};


export default FaqList;
