import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { SetGolfclub, SetFirstSelector } from '../../../redux/actions/ReservationActions';

require('./ReservationGolfclubStep.scss');

class ReservationGolfclubMap extends Component {
    markers = [];

    infowindows = [];

    renderMap = () => {
      const { golfclubs } = this.props;

      const bounds = new window.google.maps.LatLngBounds();
      golfclubs.forEach((club) => {
        bounds.extend(new window.google.maps.LatLng(club.lat, club.lon));
      });
      this.map = new window.google.maps.Map(document.getElementById('map'), {
        zoom: 12,
      });
      this.map.fitBounds(bounds);
      this.renderMarkers();
    }

    renderClickHandler = club => (
      `document.getElementById('btn-trigger-${club.id}').click()`
    )


    renderMarkerWindow = club => `<div class="reservationCourse-map__window">
            <h1 class="reservationCourse-map__window__title">${club.name}</h1>
            <h2 class="reservationCourse-map__window__city">${club.city}</h2>
            ${club.reservable && `<button class="reservationCourse-map__window__button"
            type="button"
            onclick="${this.renderClickHandler(club)}"
            >kies deze baan</button>`}
            </div>
            `

    renderMarkers = () => {
      const { golfclubs } = this.props;
      golfclubs.forEach((club, i) => {
        const position = new window.google.maps.LatLng(club.lat, club.lon);

        const iconGreen = {
          url: '/marker-green.png',
          // This marker is 20 pixels wide by 32 pixels high.
          size: new window.google.maps.Size(54, 86),
          origin: new window.google.maps.Point(-15, 0),
          // The anchor for this image is the base of the flagpole at (0, 32).
          anchor: new window.google.maps.Point(30, 40),
          scaledSize: new window.google.maps.Size(27, 43),

        };

        const iconGrey = {
          ...iconGreen,
          url: '/marker-grey.png',
        };

        window.setTimeout(() => {
          const marker = new window.google.maps.Marker({
            position,
            map: this.map,
            animation: window.google.maps.Animation.DROP,
            icon: club.reservable ? iconGreen : iconGrey,
          });
          const infowindow = new window.google.maps.InfoWindow({ content: this.renderMarkerWindow(club) });

          marker.addListener('click', () => {
            //   debugger; //eslint-disable-line
            infowindow.open(this.map, marker);
          });

          this.markers.push(marker);
        }, i * 40);
      });
    }

    componentDidMount = () => {
      setTimeout(this.renderMap, 500);
    }

    render() {
      const { golfclubs, setSelectedGolfclub } = this.props;
      return (
        <div className="reservationCourse-map">
          <div id="map" />
          <div className="reservationCourse-map__buttons">
            {golfclubs.map(club => (
              <button
                type="button"
                id={`btn-trigger-${club.id}`}
                onClick={() => { setSelectedGolfclub(club, true); }}
              >
-
              </button>
            ))}
          </div>
        </div>

      );
    }
}

ReservationGolfclubMap.propTypes = {
  golfclubs: PropTypes.arrayOf(PropTypes.any).isRequired,
  setSelectedGolfclub: PropTypes.func.isRequired,
};

const mapStateToProps = ({ reservation }) => ({ reservation });
const mapDispatchToProps = { setGolfclub: SetGolfclub, setFirstSelector: SetFirstSelector };
export default connect(mapStateToProps, mapDispatchToProps)(ReservationGolfclubMap);
