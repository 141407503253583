import React, { Component } from 'react';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import logo from '../../assets/logo.png';

import './Hamburger.css';
import './Header.scss';
import Menu from '../Menu/Menu';

class Header extends Component {
  static propTypes = {
    fullPage: PropTypes.bool.isRequired,
  }

  constructor() {
    super();
    this.state = { menuActive: false }; // eslint-disable-line
  }

  componentDidUpdate(newState) {
    const { location } = this.props; // eslint-disable-line

    if (newState.location !== location) {
      this.setState({ menuActive: false }); // eslint-disable-line
    }
  }

  toggleMenu = () => {
    const { menuActive } = this.state;
    this.setState({ menuActive: !menuActive });
  }

  render() {
    const { menuActive } = this.state;
    const { fullPage } = this.props;

    return (
      <div>
        <header className={classNames({ devEnv: process.env.NODE_ENV !== 'production' })}>
          <img src={logo} alt="logo" className="header-logo" />
          <button
            className={classNames('hamburger hamburger--arrow', { 'is-active': menuActive })}
            type="button"
            onClick={this.toggleMenu}
          >
            <span className="hamburger-box">
              <span className="hamburger-inner" />
            </span>
          </button>

        </header>
        {process.env.NODE_ENV !== 'production' && (
        <div className="header-env-warn">
          build-env:
          <strong>{process.env.NODE_ENV}</strong>
        </div>
        )}
        <Menu menuActive={menuActive} fullPage={fullPage} />
      </div>

    );
  }
}

export default withRouter(Header);
