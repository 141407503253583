import React, { Component, useEffect, useState } from 'react';
import { Row, Grid, Col } from 'react-bootstrap';
// import { NavLink } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import Loader from '../../Loader/Loader';
import SubscriptionCard from '../SubscriptionCard/SubscriptionCard';
import RenewSubscriptionCard from '../RenewSubscriptionCard/RenewSubscriptionCard';
import BonusRightsCard from '../BonusRightsCard/BonusRightsCard';
import GetApiHeaders from '../../../utils/GetApiHeaders';

require('./SubscriptionOverview.scss');


const SubscriptionOverview = () => {
  const [loading, setLoading] = useState(true);
  const [errorMsg, setErrorMsg] = useState('');
  const [subscriptions, setSubscriptions] = useState({});
  const [hasOrdered, setHasOrdered] = useState(false);

  const fetchSubscriptions = async () => {
    setLoading(true);

    const data = await fetch(`${process.env.CONF_API_URL}/api/users/me/subscriptions`, {
      method: 'GET',
      ...GetApiHeaders(),
    })
      .then((res) => {
        if (res.status === 401) {
          window.location = '/auth/login?redirect=/subscriptions';
        } return res.json();
      });
    if (!data.success) {
      setLoading(false);
      setErrorMsg(data.error || 'fout bij het ophalen van abonnementen');
    } else {
      setLoading(false);
      setSubscriptions(data.data);
    }
  };


  useEffect(() => {
    fetchSubscriptions();
    setHasOrdered(new URLSearchParams(window.location.search).get('ordered') === 'true');
  }, []);


  return (
    <section className="wizardStep wizardStep--withMenu">
      <Grid className="wizardStep__section">
        <div className="wizardStep__section__wrapper">
          {loading && <Loader className="dataLoader" size={36} />}

          {!loading && hasOrdered && (

            subscriptions && subscriptions.purchaseMessage ? (
              <span className="notification notification--success mb-2">
                {subscriptions.purchaseMessage}
              </span>
            ) : (
              <span className="notification notification--warning mb-2">
                De betaling van je extra vrije voucher is niet geslaagd.
              </span>
            )

          )}

          {!loading && errorMsg && (
          <p>
            <strong>Fout bij het ophalen van vouchers:</strong>
            {' '}
            {errorMsg}
          </p>
          )}

          {!loading && subscriptions && subscriptions.extension_options && (
              <>
                {!subscriptions.current
                  && (<h1 className="h1--renewable">Je hebt geen abonnement, kies één van onze abonnementen</h1>)}

                {subscriptions.current
                  && (<h1 className="h1--renewable">Je abonnement verloopt bijna, verleng hier je abonnement!</h1>)}

                {subscriptions.extension_options.personal_offers.length > 0 && (
                  <h2>Persoonlijk verlengaanbod</h2>
                )}
                <div>
                  {subscriptions.extension_options.personal_offers.map(
                    (offer => (<RenewSubscriptionCard offer={offer} />)),
                  )}
                </div>
                {subscriptions.extension_options.public_offers.length > 0 && (
                  <h2>Standaard abonnementen</h2>
                )}
                <div>
                  {subscriptions.extension_options.public_offers.map(
                    (offer => (<RenewSubscriptionCard offer={offer} />)),
                  )}
                </div>
                <br /><br /><br />
              </>
          )}


          {!loading && subscriptions && subscriptions.upcoming && (
          <h2 className="mb-2">Toekomstig abonnement</h2>
          )}
          {!loading && subscriptions.upcoming
              && (
                <><SubscriptionCard
                  subscription={subscriptions.upcoming}
                  type="upcoming"
                />
                  <br /></>
              )}


          <h2 className="mb-2">Huidig abonnement</h2>
          {!loading && !subscriptions.current && (
          <strong>Je hebt momenteel geen actief abonnement</strong>
          )}

          {!loading && subscriptions.current
              && (
                <SubscriptionCard
                  subscription={subscriptions.current}
                  type="current"
                />
              )}

          {!loading && (
              <><br /><br />
                <span className="mb-1 d-block">
                  <h2 className="d-inline">Bonus vouchers &amp; vrije vouchers&nbsp;</h2>
                  <br />
                  <a
                    className="d-inline-block color-white a--white ml-3 pl-3"
                    href="/vouchers/buy"
                  >
&raquo; vrije voucher bijkopen
                  </a>
                </span>
                <Row className="subscriptions__bonusrights">
                  {subscriptions.bonus
                    && subscriptions.bonus.map(bonusrights => (
                      <>
                        <BonusRightsCard
                          bonusrights={bonusrights}
                          key={bonusrights.id}
                        />
                      </>
                    ))}

                  {!subscriptions.bonus && (
                  <Col>
                        U heeft geen bonus vouchers.
                  </Col>
                  )}
                </Row>
              </>
          )}

          {/*         {!loading && subscriptions.bonus && ( */}
          {/*             <><br /><br /> */}
          {/*               <span className="mb-1 d-block"> */}
          {/*                 <h2 className="d-inline">Vrije vouchers &nbsp;</h2> */}
          {/*               </span> */}
          {/*               <Row className="subscriptions__bonusrights"> */}
          {/*                 <Col> */}
          {/*                 U heeft momenteel geen vrije vouchers. */}
          {/* &nbsp; */}
          {/*                   <a */}
          {/*                     className="d-inline-block color-white a--white a--underline ml-3 pl-3" */}
          {/*                     href="/vouchers/buy" */}
          {/*                   > */}
          {/*                   &raquo; voucher bijkopen */}
          {/*                   </a> */}
          {/*                 </Col> */}
          {/*               </Row> */}
          {/*             </> */}

          {/*         )} */}


          {!loading && subscriptions.historic
              && (
                  <><br /><br /><h2 className="mb-2">Vorige abonnementen</h2> </>
              )}

          <div className="subscriptions__historic">
            {!loading && subscriptions.historic
                && subscriptions.historic.map(subscription => (
                  <SubscriptionCard
                    subscription={subscription}
                    type="historic"
                    key={subscription.id}
                  />
                ))}
          </div>
        </div>
      </Grid>
    </section>
  );
};

export default SubscriptionOverview;
