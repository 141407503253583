import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import './Loader.scss';


const Loader = ({ className, size, color }) => (
  <div className={classNames('loader', `size-${size}`, `color-${color}`, className)}>
    <svg className="circular" viewBox="25 25 50 50">
      <circle className="path" cx="50" cy="50" r="20" fill="none" strokeWidth="4" strokeMiterlimit="10" />
    </svg>
  </div>
);

Loader.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.number,
};
Loader.defaultProps = {
  className: '',
  size: 24,
  color: 'white',
};

export default Loader;
