const GetApiHeaders = (headers = {}) => ({
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${localStorage.getItem('access_token')}`,
    ...headers,
  },
});


export default GetApiHeaders;
