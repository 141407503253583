
export default (state = {}, action) => {
  switch (action.type) {
    case 'FETCH_DATA': {
      const {
        payload: {
          scope, endpoint, headers,
        },
      } = action;
      const requestHeaders = {
        // mode: 'no-cors',
        // credentials: 'include',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      };
      fetch(process.env.CONF_API_URL + action.payload.endpoint, requestHeaders)
        .then((response) => {
          if (!response.ok) {
            throw response;
          } else {
            return response.json();
          }
        })
        .then((data) => {
          action.asyncDispatch({
            type: 'FETCHED_DATA',
            payload: { ...action.payload, data },
          });
        })
        .catch((error) => {
          action.asyncDispatch({
            type: 'FETCHED_DATA_ERROR',
            payload: { ...action.payload, error },
          });
        });
      const newState = { ...state };
      newState[scope] = { endpoint, headers, loading: true };
      return newState;
    }

    case 'FETCHED_DATA': {
      const {
        payload: {
          scope, endpoint, data,
        },
      } = action;
      const newState = { ...state };
      newState[scope] = {
        endpoint, loading: false, ...data,
      };
      return newState;
    }


    case 'FETCHED_DATA_ERROR': {
      const newState = state;
      newState[action.payload.scope] = {
        endpoint: action.payload.endpoint, loading: false, error: true, message: action.payload.error.message,
      };

      return { ...newState };
    }

    default:
      return state;
  }
};

/*
{
type: 'FETCH_DATA',
payload: {url: 'http://localhost/api/reservation/players/historic',
headers: {}, scope: 'historicPlayers',}
}
{
type: 'FETCH_DATA',
payload: {url: 'https://reqres.in/api/users?page=2', headers: {}, scope: 'historicPlayers',}
}
*/
