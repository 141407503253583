import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
// import classNames from 'classnames';
import ReservationWizardButtons from '../../ReservationWizardButtons/ReservationWizardButtons';
import ReservationCloseButton from '../../ReservationWizard/ReservationCloseButton/ReservationCloseButton';

require('./ReservationOverviewStep.scss');

const renderFriendlyDate = (date) => {
  const days = ['zondag', 'maandag', 'dinsdag', 'woensdag', 'donderdag', 'vrijdag', 'zaterdag'];
  const months = ['januari', 'februari', 'maart', 'april', 'mei', 'juni', 'juli', 'augustus', 'september', 'oktober', 'november', 'december']; // eslint-disable-line max-len
  const generatedDate = new Date(date);
  return `${days[generatedDate.getDay()]} <strong>${generatedDate.getDate()} ${months[generatedDate.getMonth()]}</strong>`; // eslint-disable-line max-len
};

const renderPlayers = players => (
  players.map(player => (
    `<strong>${player.first_name} ${player.prefix || ''} ${player.last_name}</strong><br />`
  )).join('')
);


const ReservationOverviewStep = ({
  reservation: {
    holes, players, golfclub, date,
  },
}) => (
  <section className="wizardStep wizardStepBack">
    <Grid className=" wizardStep__section">
      <ReservationCloseButton />

      <div className="wizardStep__section__wrapper">
        <h2>Controle</h2>
        <h1>Controleer je reservering</h1>

        <Row>
          {!!players.length && (
          <Col xs={12} sm={6}>
            <span className="overview-title">Spelers</span>
            <span className="overview-value" dangerouslySetInnerHTML={{ __html: renderPlayers(players) }} />
          </Col>
          )}

          {golfclub && (

          <Col xs={12} sm={6}>
            <span className="overview-title">Golfbaan</span>
            <span
              className="overview-value"
              dangerouslySetInnerHTML={
                {
                  __html: `<strong>${golfclub.name}</strong>
                <br />${golfclub.city}<br /> <small>${golfclub.reservation_instruction}</small>`,
                }}
            />
          </Col>

          )}
        </Row>

        <Row>

          {date && (
          <Col xs={7} sm={6}>
            <span className="overview-title">Datum</span>
            <span className="overview-value" dangerouslySetInnerHTML={{ __html: renderFriendlyDate(date) }} />
          </Col>
          )
        }

          {holes && (
          <Col xs={5} sm={6}>
            <span className="overview-title">Holes</span>
            <span className="overview-value">
              <strong>{holes}</strong>
            </span>
          </Col>
          )}


        </Row>

        <ReservationWizardButtons
          nextPath="/reservation/confirm"
          backHidden
          nextDisabled={!date || !holes || !golfclub || !players}
          nextText="bevestig reservering"
        />
      </div>
    </Grid>
  </section>
);

ReservationOverviewStep.propTypes = {
  reservation: PropTypes.objectOf(PropTypes.any).isRequired,
};

const mapStateToProps = ({ reservation }) => ({ reservation });
export default connect(mapStateToProps)(ReservationOverviewStep);
