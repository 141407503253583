import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  Route, withRouter, Switch, Redirect,
} from 'react-router-dom';
import { connect } from 'react-redux';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

import ReservationPartnersStep from '../ReservationWizardSteps/ReservationPartnersStep/ReservationPartnersStep';
import Loader from '../Loader/Loader';

import './ReservationWizard.scss';
import './ReservationWizardTransitions.scss';
import ReservationHolesStep from '../ReservationWizardSteps/ReservationHolesStep/ReservationHolesStep';
import ReservationGolfclubStep from '../ReservationWizardSteps/ReservationGolfclubStep/ReservationGolfclubStep';
import ReservationDateStep from '../ReservationWizardSteps/ReservationDateStep/ReservationDateStep';
import ReservationOverviewStep from '../ReservationWizardSteps/ReservationOverviewStep/ReservationOverviewStep';
import ReservationConfirmStep from '../ReservationWizardSteps/ReservationConfirmStep/ReservationConfirmStep';

class ReservationPage extends Component {
  static propTypes = {
    location: PropTypes.objectOf(PropTypes.any).isRequired, //eslint-disable-line
  }

  constructor() {
    super();
    this.state = { initialLoading: true, animateBack: false };
  }

  componentDidMount() {
    setTimeout(() => { this.setState({ initialLoading: false }); }, 800);
  }

  toggleAnimateBack() {
    this.setState({ animateBack: true });
    setTimeout(() => { this.setState({ animateBack: false }); }, 200);
  }

  render() {
    const { initialLoading, animateBack } = this.state;
    const { location } = this.props;

    if (initialLoading) return (<Loader className="wizardLoader" size={36} />);

    return (
      <div>
        <TransitionGroup>
          <CSSTransition
            key={location.pathname}
            classNames={classNames({ wizardStep: true, wizardStepBack: animateBack })}
            timeout={{
              enter: 1000,
              exit: 1000
              ,
            }}
          >
            <Route
              location={location}
              render={() => (
                <Switch>
                  <Redirect from="/reservation" exact to="/reservation/players" />

                  <Route path="/reservation/players" component={ReservationPartnersStep} />
                  <Route path="/reservation/holes" component={ReservationHolesStep} />
                  <Route path="/reservation/course" component={ReservationGolfclubStep} />
                  <Route path="/reservation/date" component={ReservationDateStep} />
                  <Route path="/reservation/overview" component={ReservationOverviewStep} />
                  <Route path="/reservation/confirm" component={ReservationConfirmStep} />

                </Switch>
              )}
            />
          </CSSTransition>
        </TransitionGroup>
      </div>
    );
  }
}


const mapStateToProps = ({ reservation }) => ({ reservation });

export default connect(mapStateToProps)(withRouter(ReservationPage));
