import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { Grid } from 'react-bootstrap';
import './ReservationWizardButtons.scss';


const ReservationWizardButtons = ({
  nextPath,
  nextText,
  nextDisabled,
  backPath,
  backText,
  backHidden,
}) => (
  <Grid className="reservationWizard__buttons">
    {!backHidden && (
    <button
      type="button"
      className={
        classNames({
          reservationWizard__buttons__button: true,
          reservationWizard__buttons__button__back: true,
        })}
    >
      <Link to={backPath}>
        {backText}
      </Link>

    </button>
    )}
    <button
      type="button"
      className={
        classNames({
          reservationWizard__buttons__button: true,
          reservationWizard__buttons__button__next: true,
          'reservationWizard__buttons__button__next--disabled': nextDisabled,
        })}
    >
      <Link to={nextPath}>
        {nextText}
      </Link>
    </button>
  </Grid>
);

ReservationWizardButtons.propTypes = {
  nextPath: PropTypes.string,
  nextText: PropTypes.string,
  nextDisabled: PropTypes.bool,
  backPath: PropTypes.string,
  backText: PropTypes.string,
  backHidden: PropTypes.bool,
};

ReservationWizardButtons.defaultProps = {
  nextPath: '',
  nextText: 'verder',
  nextDisabled: false,
  backPath: '',
  backText: 'terug',
  backHidden: false,
};

export default ReservationWizardButtons;
