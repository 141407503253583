import React from 'react';
import classNames from 'classnames';
import { NavLink, withRouter } from 'react-router-dom';
import { Grid } from 'react-bootstrap';
import PropTypes from 'prop-types';


import './Menu.scss';

const Menu = (props) => {
  const { menuActive, fullPage } = props;
  const hasSubscription = !!localStorage.has_subscription && localStorage.has_subscription !== 'false';
  return (
    <Grid bsClass="container menu-wrapper" className={classNames({ fullPage })}>

      <ul className={classNames({ active: menuActive })}>
        <li><NavLink activeClassName="active" exact to="/reservations">Reserveringen</NavLink></li>
        <li><NavLink activeClassName="active" to="/reservation/players">Reserveren</NavLink></li>
        {hasSubscription && (<li><NavLink activeClassName="active" to="/news" exact>Nieuwsberichten</NavLink></li>)}
        {hasSubscription && <li><NavLink activeClassName="active" to="/courses">Baaninformatie</NavLink></li>}
        <li><NavLink activeClassName="active" to="/faq">FAQ</NavLink></li>
        <li>
          <NavLink activeClassName="active" to="/subscriptions">
            {'Abo\'s'}
            {' '}
&amp; vouchers
          </NavLink>
        </li>
        <li><NavLink activeClassName="active" to="/profile">Profiel</NavLink></li>
        <li><NavLink activeClassName="active" to="/contact">Contact</NavLink></li>
        <li><NavLink className="menu--logout" activeClassName="active" to="/auth/logout">uitloggen</NavLink></li>
      </ul>
    </Grid>
  );
};

Menu.propTypes = {
  menuActive: PropTypes.bool.isRequired,
  fullPage: PropTypes.bool.isRequired,
};

export default withRouter(Menu);
