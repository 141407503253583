import React, { Component } from 'react';
import PropTypes from 'prop-types';
// import classNames from 'classnames';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Grid, Row, Col } from 'react-bootstrap';

import './ForgotPassword.scss';
import '../ReservationWizardAutoComplete/ReservationWizardAutoComplete.scss';
import Loader from '../Loader/Loader';
import errorIcon from '../ReservationWizardAutoComplete/error-icon.svg';


class ForgotPassword extends Component {
  static propTypes = {
    location: PropTypes.objectOf(PropTypes.any).isRequired, //eslint-disable-line
  }

  constructor() {
    super();
    this.state = {
      loginError: false, email: '', mailSent: false, loading: false,
    };
  }

  componentDidMount() {
    // setTimeout(() => { this.setState({ initialLoading: false }); }, 800);
  }

  onSubmit = (e) => {
    e.preventDefault();
    this.requestEmail();
    this.setState({ loading: true });
  }

  requestEmail = () => {
    const { email } = this.state;
    const payload = { email };

    fetch(`${process.env.CONF_API_URL}/api/users/forgot_password`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    })
      .then(response => this.handleEmailRequest(response));
  }

  handleEmailRequest = (response) => {
    this.setState({ loading: false, loginError: '' });

    if (response.status === 200) {
      this.setState({ mailSent: true });
    } else {
      this.setState({ loginError: 'onbekende fout' });
    }
  }

  render() {
    // if (initialLoading) return (<Loader className="wizardLoader" size={36} />);
    const { loginError, loading, mailSent } = this.state;
    return (
      <section className="wizardStep wizardStepBack">

        <Grid className=" wizardStep__section">
          <div className="wizardStep__section__wrapper">
            <h2>Authenticatie</h2>
            <h1>Wachtwoord vergeten</h1>
            <Row>
              <Col xs={12} sm={6} smOffset={3}>
                {!mailSent && (
                <form onSubmit={this.onSubmit}>
                  <p>
                      Vul je e-mailadres in om door te gaan:
                    <br />

                  </p>
                  <div className="wizard-autocomplete">

                    <input
                      className="wizard-autocomplete-input"
                      type="email"
                      placeholder="e-mailadres"
                      onChange={(e) => { this.setState({ email: e.target.value }); }}
                    />
                    <div className="wizard-autocomplete-modifier">
                      {loading && <Loader size={24} color="light-grey" /> }
                      {!loading && loginError
                        && <>
                          <img src={errorIcon} alt="" />
                          <span className="wizard-autocomplete-error">{loginError}</span>
                        </>}
                    </div>
                  </div>

                  <button
                    type="submit"
                    className="reservationWizard__buttons__button"
                    onSubmit={this.onSubmit}
                  >
                  wachtwoord resetten
                  </button>
                </form>
                )}

                {mailSent && (
                <p>
                      Indien het opgegeven e-mailadres bij ons bekend is ontvang je een e-mail met meer informatie.
                  <br />
                  <br />
                      Geen e-mail ontvangen? Neem contact op met de klantenservice.
                </p>
                )}

              </Col>
            </Row>
          </div>
        </Grid>
      </section>
    );
  }
}


const mapStateToProps = ({ reservation }) => ({ reservation });

export default connect(mapStateToProps)(withRouter(ForgotPassword));
