const defaultState = { info: [] };

export default (state = { ...defaultState }, action) => {
  switch (action.type) {
    case 'SET_USER_INFO':
      return { ...state, info: action.payload.info };

    default:
      return state;
  }
};
