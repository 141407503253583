import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { SetHoles } from '../../../redux/actions/ReservationActions';
import ReservationWizardButtons from '../../ReservationWizardButtons/ReservationWizardButtons';
import ReservationCloseButton from '../../ReservationWizard/ReservationCloseButton/ReservationCloseButton';

require('./ReservationHolesStep.scss');

const availableHoles = [9, 18];

const renderDisabledHoleMessage = (hole, players) => {
  const nearestHole = availableHoles[availableHoles.indexOf(hole) - 1];

  const currentHoleDisabledPlayers = players.filter(player => (+player.playrights.this_month.max_holes === nearestHole)); // eslint-disable-line max-len
  const lowerHoleDisabledPlayers = players.filter(player => (+player.playrights.this_month.max_holes < hole));

  if (currentHoleDisabledPlayers.length) {
    if (currentHoleDisabledPlayers.length === 1) {
      // eslint-disable-next-line max-len
      return `<strong>${currentHoleDisabledPlayers[0].first_name}</strong> kan max. ${currentHoleDisabledPlayers[0].playrights.this_month.max_holes} holes spelen.`;
    }

    let message = '';
    currentHoleDisabledPlayers.forEach((player, index) => {
      if (index + 1 === currentHoleDisabledPlayers.length) {
        message = message.slice(0, -2);
        // eslint-disable-next-line max-len
        message += ` en <strong>${player.first_name}</strong> kunnen max. ${currentHoleDisabledPlayers[0].playrights.this_month.max_holes} holes spelen.`;
      } else {
        message += `<strong>${player.first_name}</strong>, `;
      }
    });
    return message;
  }

  if (lowerHoleDisabledPlayers) {
    if (lowerHoleDisabledPlayers.length === 1) {
      // eslint-disable-next-line max-len
      return `<strong>${lowerHoleDisabledPlayers[0].first_name}</strong> kan max. ${lowerHoleDisabledPlayers[0].playrights.this_month.max_holes} holes spelen.`;
    }
    let message = '';
    lowerHoleDisabledPlayers.forEach((player, index) => {
      if (index + 1 === lowerHoleDisabledPlayers.length) {
        message = message.slice(0, -2);
        // eslint-disable-next-line max-len
        message += ` en <strong>${player.first_name}</strong> kunnen max. ${lowerHoleDisabledPlayers[0].playrights.this_month.max_holes} holes spelen.`;
      } else {
        message += `<strong>${player.first_name}</strong>, `;
      }
    });
    return message;
  }
  return false;
};

const ReservationHolesStep = ({
  reservation: {
    holes, players, golfclub, isPredefinedCourse,
  }, setHoles,
}) => (
  <section className="wizardStep wizardStepBack">

    <Grid className=" wizardStep__section">
      <ReservationCloseButton />
      <div className="wizardStep__section__wrapper">
        <h2>Holes</h2>
        <h1>Kies het aantal holes</h1>

        {availableHoles.map((hole) => {
          const disabledHoleMessage = renderDisabledHoleMessage(hole, players);
          return (
            <Row key={hole}>
              <Col
                xs={12}
                onClick={() => !disabledHoleMessage && setHoles(hole)}
                className={classNames('holes-hole', { 'holes-hole--disabled': disabledHoleMessage })}
              >
                <span className={classNames('holes-radio', { 'holes-radio--active': holes === hole })} />
                <span className="holes-label">
                  <strong>
                    {hole}
                  </strong>
                  {' '}
                holes
                  {disabledHoleMessage
                  && (
                  <span
                    className="holes-label--disabled"
                    dangerouslySetInnerHTML={{ __html: disabledHoleMessage }}
                  />
                  )
}
                </span>
              </Col>
            </Row>
          );
        })}

        <ReservationWizardButtons
          backText="terug naar spelers"
          backPath="/reservation/players"
          nextPath={isPredefinedCourse && golfclub ? '/reservation/date' : '/reservation/course'}

          nextDisabled={!holes || !!renderDisabledHoleMessage(holes, players)}
          nextText="verder"
        />
      </div>
    </Grid>
  </section>
);

ReservationHolesStep.propTypes = {
  reservation: PropTypes.objectOf(PropTypes.any).isRequired,
  setHoles: PropTypes.func.isRequired,
};

const mapStateToProps = ({ reservation }) => ({ reservation });
const mapDispatchToProps = { setHoles: SetHoles };
export default connect(mapStateToProps, mapDispatchToProps)(ReservationHolesStep);
